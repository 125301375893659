:root {
  --mui-primary-color: #591DFF;
  --mui-primary-color-light: #9B77FF;
  --mui-primary-color-contrast: #FFFBFE;
}

html {
  height: 100%;
}

body {
  height: 100%;
}

#root {
  height: 100%;
}

.blurred-value {
  filter: blur(8px);
  pointer-events: none;
}

#form-checkout {
  display: flex;
  flex-direction: column;
  max-width: 600px;
}

.container {
  height: 18px;
  display: inline-block;
  border: 1px solid rgb(118, 118, 118);
  border-radius: 2px;
  padding: 1px 2px;
}

.mdx-content-montu-b {
  border: 1px solid #ccc; /* Borde ligero */
  border-radius: 8px; /* Esquinas redondeadas */
  border-top: 0;
}